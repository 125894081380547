import React from "react";

const FaqsButton = ({onClick}) => {
  return (
    <button className="knoto-button" onClick={onClick}>
      Knoto
    </button>
  );
};

export default FaqsButton;